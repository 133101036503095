//import { useQuery } from "@apollo/client";
import { Contract } from "@ethersproject/contracts";
import {
  ChainId,
  shortenAddress,
  useEthers,
  useCall,
  useToken,
  useTokenBalance,
  useContractFunction,
  useLookupAddress,
  Rinkeby,
} from "@usedapp/core";
import React, { useEffect, useState } from "react";
import { BsTwitter } from "react-icons/bs";
import { IconContext } from "react-icons";
import {
  Body,
  Button,
  Container,
  Header,
  Footer,
  HeaderMenu,
  HeaderLogo,
  HeaderRight,
} from "./components";
import { formatUnits } from "@ethersproject/units";

import { addresses, abis } from "@my-app/contracts";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {MintForm} from "./MintForm";
import RedeemForm from "./RedeemForm";
import TransferForm from "./TransferForm";
import ViewerForm from "./ViewerForm";
import SamplesForm from "./SamplesForm";
import ReadMeForm from "./ReadMeForm";
import { SUPPORTED_CHAIN } from ".";
import { getChainMeta } from "@usedapp/core/dist/esm/src/helpers";
import { Goerli } from "@usedapp/core";

export const statusStyle = {
  color: "gray",
  fontSize: "0.8em",
  fontStyle: "italic",
};



export const ConnectWallet = () => {
  return <div style={{ color: "gray" }}>Wallet not connected</div>;
};

function WalletButton() {
  const [rendered, setRendered] = useState("");

  const ens = useLookupAddress();
  const { account, chainId, activateBrowserWallet, deactivate, error } =
    useEthers();

  //Faucet Contract.
const faucetContract = new Contract(
  addresses.wbtcFaucet,
  abis.wbtcFaucet
);

  //Faucet for test WBTC Contract.
  const {
    state: faucetState,
    send: faucetSend,
    resetState: faucetResetState,
  } = useContractFunction(faucetContract, "requestToken", {
    transactionName: "RequestWBTC",
  });

  const handleFaucet = async (event) => {
    event.preventDefault();
    await faucetSend(2500000000);
  };

  useEffect(() => {
    if (ens) {
      setRendered(ens);
      if (chainId && chainId !== SUPPORTED_CHAIN) {
        alert("Unsupported network.");
      }
    } else if (account) {
      setRendered(shortenAddress(account));
      if (chainId && chainId !== SUPPORTED_CHAIN) {
        alert("Unsupported network. Switch to " + getChainMeta(SUPPORTED_CHAIN).chainName);
      }
    } else {
      setRendered("");
    }
  }, [account, ens, chainId, setRendered]);

  useEffect(() => {
    if (error) {
      console.error("Error while connecting wallet:", error.message);
    }
  }, [error]);

  useEffect(() => {
    if (faucetState && faucetState.status === "Success") {
      faucetResetState();
      alert("25 WBTC withdrawn.Success.")
    }
    if (faucetState && faucetState.status === "Exception") {
      faucetResetState();
      alert(faucetState.errorMessage);
    }
    if (faucetState && faucetState.status === "Fail") {
      faucetResetState();
      alert(faucetState.errorMessage);
    }
    return;
  }, [
    faucetSend,
    faucetState,
    faucetResetState,
  ]);

  return (
    <HeaderRight>
      <label>
        {rendered === "" && ""}
        {rendered !== "" && ChainId[chainId]}
      </label>
      <Button
        onClick={() => {
          if (window.ethereum) {
            if (!account) {
              activateBrowserWallet();
            } else {
              deactivate();
            }
          } else {
            alert("Metamask not installed.");
          }
        }}
      >
        {rendered === "" && "Connect Wallet"}
        {rendered !== "" && rendered}
      </Button>
      {window.ethereum && account && chainId === Rinkeby.chainId? 
          <Button
          onClick={
            handleFaucet
          }>
          Get 25 WBTC
        </Button>
          :
          ''}
    </HeaderRight>
  );
}

//Deposit Token Contract.
export const depositTokenContract = new Contract(
  addresses.depositToken,
  abis.erc20
);
//Stone Age Bank Contract.
export const bitsNftBankContract = new Contract(
  addresses.bitsNftBank,
  abis.bitsNftBank
);
//Stone Age Mint Contract.
export const bitsNftMintContract = new Contract(
  addresses.bitsNftMint,
  abis.bitsNftMint
);
//Stone Age Uri Contract.
export const bitsNftUriContract = new Contract(
  addresses.bitsNftUri,
  abis.bitsNftUri
);



// function DepositToken() {
//   return useToken(addresses.depositToken);
// }


function App() {
 // console.log(addresses.depositToken);
  const [totalSupply, setTotalSupply] = useState(0);
  const depositToken = useToken(addresses.depositToken);
  //Get Total Supply
  const { error, value } =
    useCall({
      contract: new Contract(addresses.bitsNftMint, abis.bitsNftMint),
      method: "totalSupply",
    }) ?? {};

    //Get BalanceOf
    const bankBalance = useTokenBalance(addresses.depositToken, addresses.bitsNftBank);

    useEffect(() => {
      if (error) {
        console.error("Error while connecting wallet:", error.message);
      }
      if (value) {
        setTotalSupply(value);
        //setIndex(value - 1);
      }
    }, [error, value, totalSupply]);

 //   console.log("total supply: " + totalSupply);

  return (
    <Container>
      <Tabs>
        <Header>
          <HeaderLogo>BiTS</HeaderLogo>
          <HeaderMenu>
            <TabList>
              <Tab>MINT</Tab>
              <Tab>REDEEM</Tab>
              <Tab>TRANSFER</Tab>
              <Tab>VIEWER</Tab>
              <Tab>SAMPLES</Tab>
              <Tab>README</Tab>
            </TabList>
          </HeaderMenu>
          <HeaderRight>
            <WalletButton />
          </HeaderRight>
        </Header>
        <Body>
          <TabPanel>
            <MintForm totalSupply={totalSupply} depositToken={depositToken}/>
          </TabPanel>
          <TabPanel>
            <RedeemForm depositToken={depositToken}/>
          </TabPanel>
          <TabPanel>
            <TransferForm depositToken={depositToken} />
          </TabPanel>
          <TabPanel>
            <ViewerForm />
          </TabPanel>
          <TabPanel>
            <SamplesForm depositToken={depositToken}/>
          </TabPanel>
          <TabPanel>
            <ReadMeForm depositToken={depositToken}/>
          </TabPanel>
        </Body>
        <Footer>
        <div>
          <label><span style={{ color: "#eeb32b" }}>BiTS</span> in circulation : {totalSupply ? totalSupply.toString() : ""}</label>&emsp;|&emsp;
          <label>Bank Balance : {bankBalance ? formatUnits(bankBalance.toString(), depositToken?.decimals)+" "+depositToken?.symbol : ""}</label>&emsp;|&emsp;
          
            <a href="https://twitter.com/bitsnftxyz" target="_blank" rel="noreferrer">
          <IconContext.Provider
              value={{ style: {fontSize: '30px', color: "#1DA1F2", verticalAlign:"bottom"}}}
            >
            <BsTwitter></BsTwitter>
            </IconContext.Provider>
            </a>
            </div>
        </Footer>
      </Tabs>
    </Container>
  );
}

export default App;
