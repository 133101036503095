import { Contract } from "@ethersproject/contracts";
import {
  useCall,
  useCalls,
  useContractFunction,
  useEthers,
} from "@usedapp/core";
import React, { useEffect, useRef, useState } from "react";
import {
  ConnectWallet,
  statusStyle,
  bitsNftMintContract,
  bitsNftBankContract,
} from "./App.js";
import { addresses, abis } from "@my-app/contracts";
import ReactTooltip from "react-tooltip";
import { BsInfoCircle } from "react-icons/bs";
import { IconContext } from "react-icons";
import { FormButton, Image, FormSelect, LeftSection, RightSection } from "./components";
import { formatUnits} from "@ethersproject/units";
import { TokenSVG } from "./MintForm";

function RedeemForm(props) {
  const { account } = useEthers();
  const [redeemTokenId, setRedeemTokenId] = useState(0);
  const [indexArray, setIndexArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectTokenId = useRef(0);
  //Get Deposit Redeemable
  const { error: depositAmountToTokenIdError, value: depositAmountToTokenId } =
    useCall({
      contract: new Contract(addresses.bitsNftBank, abis.bitsNftBank),
      method: "depositAmountToTokenId",
      args: [selectTokenId.current.value],
    }) ?? {};  

  //Get BalanceOf
  const { error: balanceOfError, value: balanceOf } =
    useCall({
      contract: new Contract(addresses.bitsNftMint, abis.bitsNftMint),
      method: "balanceOf",
      args: [account],
    }) ?? {};

  const calls =
    indexArray?.map((index) => ({
      contract: new Contract(addresses.bitsNftMint, abis.bitsNftMint),
      method: "tokenOfOwnerByIndex",
      args: [account, index],
    })) ?? {};

  const results = useCalls(calls) ?? {};

  const {
    state: approveState,
    send: approveSend,
    resetState: approveResetState,
  } = useContractFunction(bitsNftMintContract, "approve", {
    transactionName: "Approve",
  });

  const {
    state: redeemState,
    send: redeemSend,
    resetState: redeemResetState,
  } = useContractFunction(bitsNftBankContract, "redeemDeposit", {
    transactionName: "RedeemDeposit",
  });

  const handleTokenIdChange = (event) => {
 //   console.log("onchange");
 event.preventDefault();
    setRedeemTokenId(event.target.value);
  };

  const handleRedeem = async (event) => {
    event.preventDefault();
    setLoading(true);
    await approveSend(addresses.bitsNftBank, redeemTokenId);
  };

  useEffect(() => {
    if (balanceOfError) {
      console.error("Error while getting balanceof:", balanceOfError.message);
    }
    if (balanceOf) {
      setIndexArray(Array.from(Array(parseInt(balanceOf?.toString())).keys()));
    }
  }, [balanceOfError, balanceOf]);


  useEffect(() => {
    setRedeemTokenId(selectTokenId.current.value);
    if (approveState && approveState.status === "Success") {
      approveResetState();
      redeemSend(redeemTokenId);
    }
    if (redeemState && redeemState.status === "Success") {
      setLoading(false);
      redeemResetState();
    }
    if (approveState && approveState.status === "Exception") {
      setLoading(false);
      approveResetState();
      alert(approveState.errorMessage);
    }
    if (redeemState && redeemState.status === "Exception") {
      setLoading(false);
      redeemResetState();
      alert(redeemState.errorMessage);
    }
    if (approveState && approveState.status === "Fail") {
      setLoading(false);
      approveResetState();
      alert(approveState.errorMessage);
    }
    if (redeemState && redeemState.status === "Fail") {
      setLoading(false);
      redeemResetState();
      alert(redeemState.errorMessage);
    }
    return;
  }, [
    approveState,
    redeemTokenId,
    redeemSend,
    redeemState,
    approveResetState,
    redeemResetState,
  ]);

  useEffect(() => {
    if (depositAmountToTokenIdError) {
      console.error("DepositAmountToTokenId failed: "+depositAmountToTokenIdError.message);
    }
  }, [depositAmountToTokenIdError]);

  console.log('redeem token id: ' + redeemTokenId);

  //console.log(results[0].value[0].toString());
  const svgImage = TokenSVG(redeemTokenId);
  const hasMintTokens = balanceOf && parseInt(balanceOf.toString()) > 0 ? true : false;
 // console.log(hasMintTokens);
  return (
    <form onSubmit={handleRedeem}>
      <LeftSection>
      <label>
        TokenId &nbsp;
        <IconContext.Provider
          value={{ size: "0.8em", style: { verticalAlign: "middle" } }}
        >
          <BsInfoCircle data-tip="Only the token owner can redeem.<br/>Redeem action is irreversible and token is burnt.<br/>Deposit amount will be transferred to BiT owner.<br/>Redeeming frees up the 'text' for re-use." />
        </IconContext.Provider>
        &emsp;
        <FormSelect
          name="tokenId"
          onChange={handleTokenIdChange}
          ref={selectTokenId}
        >
          {results.map((result, i) => (
            <option key={i} value={result?.value?.[0].toString()}>
              {result?.value?.[0].toString()}
            </option>
          ))}
        </FormSelect>
      
      &nbsp;{hasMintTokens ?  <label style={statusStyle}>({depositAmountToTokenId && formatUnits(depositAmountToTokenId?.toString(),props.depositToken?.decimals)} {props.depositToken && props.depositToken?.symbol})</label>: ''}
      </label>
      <p></p>
      <label>
          BiTS Balance &nbsp;
          
            {balanceOf ? balanceOf.toString() : ""}
        </label>
      <p></p>
      <FormButton
        type="submit"
        disabled={!account || !balanceOf || balanceOf?.toString() === "0" || loading}
      >
        {!account ? <ConnectWallet /> : "Redeem"}
      </FormButton>
      <p></p>
      <label style={statusStyle}>
      {approveState.status === "None"
        ? ""
        : "Approval..." + approveState.status}
      {redeemState.status === "None" ? "" : "Redeeming..." + redeemState.status}
      </label>
      <ReactTooltip multiline />
      </LeftSection>
      <RightSection>
      {!hasMintTokens ? (
        <label style={statusStyle}>No tokens to redeem.</label>
      ) : (
        <Image src={svgImage} alt={redeemTokenId} />
      )}
      </RightSection>
    </form>
  );
}

export default RedeemForm;
