import React, {useState } from "react";
import {
  statusStyle,
} from "./App.js";
import ReactTooltip from "react-tooltip";
import { BsInfoCircle } from "react-icons/bs";
import { IconContext } from "react-icons";
import { Image, FormInput, LeftSection, RightSection} from "./components";
import { TokenSVG } from "./MintForm";

function ViewerForm() {
  const [viewTokenId, setViewTokenId] = useState(0);

  const handleTokenIdChange = (event) => {
    setViewTokenId(event.target.value);
  };

  

  const svgImage = TokenSVG(viewTokenId);
  console.log('view token id: ' + viewTokenId);
  //console.log(svgImage);

  return (
    <form>
      <LeftSection>
          <label>
          Token Id &nbsp;
            <IconContext.Provider
              value={{ size: "0.8em", style: { verticalAlign: "middle" } }}
            >
              <BsInfoCircle data-tip="Enter token id to view.<br/> Tokens already burnt cannot be viewed." />
            </IconContext.Provider>
            &emsp;
            <FormInput
              name="tokenId"
              type="number"
              onChange={handleTokenIdChange}
              minLength={2}
              placeholder={viewTokenId}
            />
          </label>
      <p></p>
      <ReactTooltip multiline />
      </LeftSection>
      <RightSection>
      {!svgImage ? (
        <label style={statusStyle}>Invalid token id.</label>
      ) : (
        <Image src={svgImage} alt={viewTokenId} />
      )}
      </RightSection>
    </form>
  );
}

export default ViewerForm;
