import { Contract } from "@ethersproject/contracts";
import {
  useCall,
  useContractFunction,
  useEthers,
  useTokenAllowance,
} from "@usedapp/core";
import React, { useEffect, useState, useRef} from "react";
import ReactTooltip from "react-tooltip";
import { BsInfoCircle } from "react-icons/bs";
import { IconContext } from "react-icons";
import { parseUnits, formatUnits } from "@ethersproject/units";

import { Image, FormButton, FormInput, FormSelect, RightSection, LeftSection } from "./components";
import { addresses, abis } from "@my-app/contracts";
import {
  ConnectWallet,
  statusStyle,
  depositTokenContract,
  bitsNftBankContract,
} from "./App.js";



function TokenSVG(tokenId) {
  //Get Token Uri
  const { error: tokenURICallError, value: tokenURI } =
    useCall({
      contract: new Contract(addresses.bitsNftMint, abis.bitsNftMint),
      method: "tokenURI",
      args: [tokenId],
    }) ?? {};

  if (tokenURICallError)
    console.error("Error calling tokenUri :" + tokenURICallError);
  let svgImage;
  if (tokenURI) {
    try {
      let meta = JSON.parse(
        Buffer.from(tokenURI.toString().split(",")[1], "base64").toString()
      );
      svgImage = meta["image"];
    } catch (error) {
      console.log(error);
    }
    
    // console.log(svgImage);
  }
  return svgImage;
}

function SVGArray(props) {
  
  const [index, setIndex] = useState(0);
  

  const { error: tokenByIndexCallError, value: tokenByIndex } =
    useCall({
      contract: new Contract(addresses.bitsNftMint, abis.bitsNftMint),
      method: "tokenByIndex",
      args: [index],
    }) ?? {};

  

  useEffect(() => {
    if (tokenByIndexCallError) {
      console.error(
        "Error while tokenbyindexcall:",
        tokenByIndexCallError.message
      );
    }
  }, [tokenByIndexCallError]);

  useEffect(() => {
    if (props.totalSupply) {
      setIndex(props.totalSupply[0] - 1);
    }
  }, [props.totalSupply]);


  /// console.log(svgArray);

  return (
    <div >
        {tokenByIndex ? <Image src={TokenSVG(tokenByIndex?.toString())} /> : <Image src={TokenSVG(0)} />}
        <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <label>Last minted BiT</label>
      </div>
    </div>
  );
}

function MintForm(props) {
  const { account } = useEthers();
  const [userText, setUserText] = useState("");
  const [depositAmount, setDepositAmount] = useState("1");
  const [tokenId, setTokenId] = useState("");
  const [loading, setLoading] = useState(false);
  const textInput = useRef(null);

  
  
  const tokenAllowance = useTokenAllowance(
    addresses.depositToken,
    account,
    addresses.bitsNftBank
  );
  // console.log(depositToken?.symbol);
  //Get Mint Premium
  const { error: mintPremiumCallError, value: mintPremium } =
    useCall({
      contract: new Contract(addresses.bitsNftBank, abis.bitsNftBank),
      method: "mintpremiumPct",
    }) ?? {};
  //Check if usertext taken
  const { error: userTextExistsError, value: userTextExists } =
    useCall({
      contract: new Contract(addresses.bitsNftBank, abis.bitsNftBank),
      method: "userTextExists",
      args: [userText.toUpperCase()],
    }) ?? {};

  const handleUserTextChange = (event) => {
    event.preventDefault(); 
    setUserText(event.target.value);  
  };

  const handleDepositAmountChange = (event) => {
    event.preventDefault();
    setDepositAmount(event.target.value);
    
  };

  //max chars
  let maxText = "4";
  let cgasLimit = 1000000;
  let tokenApprovalAmount = 11;
  if (mintPremium) {
    let mintPremiumNumber = 1 + mintPremium.toString() / 100;
    //  console.log(mintPremiumNumber);
    if (depositAmount === "0") {
      maxText = "4";
      cgasLimit = 5000000;
      tokenApprovalAmount = (0.001 * mintPremiumNumber).toFixed(5);
    } else if (depositAmount === "1") {
      maxText = "8";
      cgasLimit = 5000000;
      tokenApprovalAmount = (0.01 * mintPremiumNumber).toFixed(5);
    } else if (depositAmount === "2") {
      maxText = "16";
      cgasLimit = 5000000;
      tokenApprovalAmount = (0.1 * mintPremiumNumber).toFixed(5);
    } else if (depositAmount === "3") {
      maxText = "32";
      cgasLimit = 7000000;
      tokenApprovalAmount = (1.0 * mintPremiumNumber).toFixed(5);
    } else if (depositAmount === "4") {
      maxText = "60";
      cgasLimit = 10000000;
      tokenApprovalAmount = (10.0 * mintPremiumNumber).toFixed(5);
    }
  }

  

  const {
    state: approveState,
    send: approveSend,
    resetState: approveResetState,
  } = useContractFunction(depositTokenContract, "approve", {
    transactionName: "Approve",
  });
  const {
    state: mintState,
    send: mintSend,
    resetState: mintResetState,
    events: mintEvents,
  } = useContractFunction(bitsNftBankContract, "mintDeposit", {
    transactionName: "MintDeposit",
  });

  const handleMint = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (userTextExists && !userTextExists[0] && !(userText==="")) {
      // console.log("Token Approval Amount " + tokenApprovalAmount);
      // console.log("TOken Allowance " + formatUnits(tokenAllowance.toString(),props.depositToken?.decimals));

      // console.log("Diffeence " + (tokenApprovalAmount - formatUnits(tokenAllowance.toString(),props.depositToken?.decimals)));
      if (
        (tokenApprovalAmount - formatUnits(tokenAllowance.toString(),props.depositToken?.decimals)) > 0
      ) {
        console.log("approval does not exist");
        await approveSend(
          addresses.bitsNftBank,
          parseUnits(tokenApprovalAmount.toString(), props.depositToken?.decimals)
        );
      } else {
        let xmlSafeText = userText.replace(/[<>&'"]/g, function (c) {
          switch (c) {
              case '<': return '*';
              case '>': return '*';
              case '&': return '*';
              case '\'': return '*';
              case '"': return '*';
              default:;
          }
        });
        console.log(xmlSafeText);
        await mintSend(xmlSafeText, depositAmount, {gasLimit: cgasLimit});
      }
    } else {
      alert("Sorry message already taken. Pick another!");
    }
    

  };

  console.log(tokenId);

  useEffect(() => {
    textInput.current.focus();
    if (approveState && approveState.status === "Success") {
      approveResetState();
      mintSend(userText, depositAmount, {gasLimit: cgasLimit});
     
    }
    if (mintState && mintState.status === "Success") {
      mintResetState();
      setLoading(false);
    }
    if (approveState && approveState.status === "Exception") {
      approveResetState();
      setLoading(false);
      alert(approveState.errorMessage);
    }
    if (mintState && mintState.status === "Exception") {
      mintResetState();
      setLoading(false);
      alert(mintState.errorMessage);
    }
    if (approveState && approveState.status === "Fail") {
      approveResetState();
      setLoading(false);
      alert(approveState.errorMessage);
    }
    if (mintState && mintState.status === "Fail") {
      mintResetState();
      setLoading(false);
      alert(mintState.errorMessage);
    }
    
  }, [
    approveState,
    mintState,
    depositAmount,
    mintSend,
    mintResetState,
    approveResetState,
    userText
  ]);

  useEffect(() => {
    if (mintEvents) {
      setTokenId(mintEvents[0]["args"]["tokenId"].toString());
    }
  }, [mintEvents]);

  useEffect(() => {
    if (userTextExistsError) {
      console.error("UserTextExists check failed: "+userTextExistsError.message);
    }
  }, [userTextExistsError]);


  useEffect(() => {
    if (mintPremiumCallError) {
      console.error(
        "Error while querying contract:",
        mintPremiumCallError.message
      );
      return;
    }
  }, [mintPremiumCallError]);

  

  return (
        <form onSubmit={handleMint} key="form1" >
          <LeftSection key="section1">
          <label>
            Deposit Amount&nbsp;
            <IconContext.Provider
              value={{ size: "0.8em", style: { verticalAlign: "middle" } }}
            >
              <BsInfoCircle data-tip="Select the amount you want to deposit into the mint.<br />Deposit can be redeemed anytime by burning the minted BiT.<br />Higher deposit units get more chars and cooler BiTs." />
            </IconContext.Provider>
            &emsp;
            <FormSelect
              name="depositAmount"
              value={depositAmount}
              onChange={handleDepositAmountChange}
            >
              <option value="0">Milli (0.001 {props.depositToken?.symbol})</option>
              <option value="1">Centi (0.01 {props.depositToken?.symbol})</option>
              <option value="2">Deci (0.1 {props.depositToken?.symbol})</option>
              <option value="3">One (1 {props.depositToken?.symbol})</option>
              <option value="4">Deca (10 {props.depositToken?.symbol})</option>
            </FormSelect>
          </label>
          <p></p>
          <label>
            Message &nbsp;
            <IconContext.Provider
              value={{ size: "0.8em", style: { verticalAlign: "middle" } }}
            >
              <BsInfoCircle data-tip="Message you want printed on your BiT.<br/>Messages are case-insensitive and unique.<br />Special characters &, >, <, ', will be replaced by *" />
            </IconContext.Provider>
            &emsp;
            <FormInput
              name="usertext"
              type="text"
              key="text1"
              onChange={handleUserTextChange}
              value={userText}
              autoFocus = "autoFocus"
              maxLength={maxText}
              placeholder={maxText + " characters"}
              ref={textInput}
            />
          </label>
          <label style={statusStyle}>
            {userTextExists && userTextExists[0] ? "Not available." : ""}
          </label>
          <p></p>
          <label>
          Mint Premium &nbsp;
          <IconContext.Provider
            value={{ size: "0.8em", style: { verticalAlign: "middle" } }}
          >
            <BsInfoCircle data-tip="One time fee for minting.<br/> Cost to mint BiT = Deposit (redeemable) + Mint Premium (one-time) + Ether gas fees.<br/>No creator royalties or fees for transfering, resale, or redemption.<br/>Mint premium can change." />
          </IconContext.Provider>
          &emsp;
            {mintPremium ? mintPremium.toString() : ""}%
          </label>
          <p></p>
          <FormButton type="submit" disabled={!account || loading}>
            {!account ? <ConnectWallet /> : "Mint"}
          </FormButton>
          <p></p>
          <label style={statusStyle}>
            {approveState.status === "None"
              ? ""
              : "Approval Status ... " + approveState.status}
            {mintState.status === "None"
              ? ""
              : "Minting Status ..." + mintState.status}
          </label>
          <ReactTooltip multiline />
          </LeftSection>
          <RightSection>
          <SVGArray totalSupply={props.totalSupply}/>
          </RightSection>
        </form>
  );
}

export { MintForm, TokenSVG };
