import styled from "styled-components";


export const Button = styled.button`
  background-color: white;
  border: 1px solid #282c34;
  border-radius: 8px;
  color: #282c34;
  cursor: pointer;
  font-size: 14px;
  margin: 0px 20px;
  padding: 8px 24px;
  text-align: center;
  text-decoration: none;
`;

export const FormButton = styled.button`
  background-color: white;
  border: 1px solid #282c34;
  border-radius: 8px;
  color: #282c34;
  cursor: pointer;
  font-size: 14px;
  margin: 0px 0px;
  padding: 8px 24px;
  text-align: center;
  text-decoration: none;
`;


export const FormInput = styled.input`
  background-color: white;
  border: 1px solid #282c34;
  border-radius: 8px;
  color: #282c34;
  cursor: pointer;
  font-size: 14px;
  margin: 0px 0px;
  padding: 8px 8px;

`;

export const ReadMeDivLeft = styled.div`
  color: #2b2b2b;
  display: flex;
  flexDirection: column;
  justify-content: flex-start;
  font-size: 18px;
  float:left;

`;
export const ReadMeDiv = styled.div`
  color: #2b2b2b;
  display: flex;
  flexDirection: column;
  justify-content:flex-start;
  font-size: 18px;
  margin-left:calc(10vw);
  width: calc(100vw);
`;

export const FormSelect = styled.select`
  background-color: white;
  border: 1px solid #282c34;
  border-radius: 8px;
  color: #282c34;
  cursor: pointer;
  font-size: 14px;
  margin: 0px 0px;
  padding: 8px 8px;

`;

export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: left;
    background-color: none;
    margin-right: 5vmin;
    height: 80vmin;
`;

export const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: right;
    background-color: none;
    margin-left: 5vmin;
    height: 80vmin;
`;

export const Container = styled.div`
//   background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
// <defs><pattern id='a' patternUnits='userSpaceOnUse' width='40' height='80' patternTransform='scale(40) rotate(267)'>
// <rect x='0' y='0' width='100%' height='100%' fill='hsla(0,100%,100%,1)'/>
// <path d='M-4.798 13.573C-3.149 12.533-1.446 11.306 0 10c2.812-2.758 6.18-4.974 10-5 4.183.336 7.193 2.456 10 5 2.86 2.687 6.216 4.952 10 5 4.185-.315 7.35-2.48 10-5 1.452-1.386 3.107-3.085 4.793-4.176'  stroke-width='5.5' stroke='%233746a7' fill='none'/>
// <path d='M-4.798 33.573C-3.149 32.533-1.446 31.306 0 30c2.812-2.758 6.18-4.974 10-5 4.183.336 7.193 2.456 10 5 2.86 2.687 6.216 4.952 10 5 4.185-.315 7.35-2.48 10-5 1.452-1.386 3.107-3.085 4.793-4.176'  stroke-width='5.5' stroke='%23a73746' fill='none'/>
// <path d='M-4.798 53.573C-3.149 52.533-1.446 51.306 0 50c2.812-2.758 6.18-4.974 10-5 4.183.336 7.193 2.456 10 5 2.86 2.687 6.216 4.952 10 5 4.185-.315 7.35-2.48 10-5 1.452-1.386 3.107-3.085 4.793-4.176'  stroke-width='5.5' stroke='%2346a737' fill='none'/>
// </pattern></defs><rect width='100%' height='100%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
//   background-size: cover;
  display: flex;
  flex-direction: column;
  height: calc(100vh);
  font-family: monospace;
`;

export const HeaderLogo = styled.div`
  width: calc(30vw);
  font-size: 30px;
  font-weight: 900;
  color: #eeb32b;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
export const HeaderMenu = styled.div``;
export const HeaderRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

export const Header = styled.header`
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-height: 70px;
  font-size: 20px;
`;

export const Body = styled.div`
  align-items: center;
  color: #282c34;
  display: flex;
  flex-direction: row;
  font-size: calc(6px + 2vmin);
  font-weight: bold;
  justify-content: center;
  margin-top: 40px;
`;

export const Footer = styled.footer`
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  position: fixed;
  width: 100%;
  min-height: 50px;
  font-weight: bold;
  font-size: 20px;

`;

export const Image = styled.img`
  height: 70vmin;
  margin-bottom: 8px;
  pointer-events: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
  margin-top: 8px;
`;
