import erc20Abi from "./abis/erc20.json";
import ownableAbi from "./abis/ownable.json";
import bitsNftBankAbi from "./abis/bitsnftbank.json";
import bitsNftMintAbi from "./abis/bitsnftmint.json";
import bitsNftUriAbi from "./abis/bitsnfturi.json";
import wbtcFaucetAbi from "./abis/wbtcfaucet.json";

const abis = {
  erc20: erc20Abi,
  ownable: ownableAbi,
  bitsNftBank: bitsNftBankAbi,
  bitsNftMint: bitsNftMintAbi,
  bitsNftUri: bitsNftUriAbi,
  wbtcFaucet: wbtcFaucetAbi,
};

export default abis;
