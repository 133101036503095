import React from "react";
import { ReadMeDiv } from "./components";
import { addresses } from "@my-app/contracts";

function ReadMeForm(props) {
  //Deposit Token Details
  // const depositToken = useToken(addresses.depositToken);

  return (
    <React.Fragment>
      <ReadMeDiv>
        <p>
          <h3>
            <span style={{ color: "#eeb32b" }}>BiTS</span> - pieces of BTC
          </h3>
          An experimental NFT project that started from a couple of what if’s
          and a love of waves.
          <ul>
            <li>What if, fractions of BTC traded as individual units.</li>

            <li>What if, NFTs were backed by a real floor.</li>
          </ul>
          <span style={{ color: "#eeb32b" }}>BiTS</span> tackles all 3 - An ERC
          721 compatible project with a Bank, a Mint, and pseudo-random waves.
        </p>
      </ReadMeDiv>
      <ReadMeDiv>
        <p>
          <h3>Traits of a <span style={{ color: "#eeb32b" }}>BiT</span></h3>
          <ul>
         <li> Types - There are 5 units of BiTS. They each represent the deposit amount backing the BiT.</li>
          <ul>
            <li>MILLI - 0.001 WBTC</li>
            <li>CENTI - 0.01 WBTC</li>
            <li>DECI - 0.1 WBTC</li>
            <li>ONE - 1 WBTC</li>
            <li>DECA - 10 WBTC</li>
          </ul>
          <li>Message - During the minting process, the requester gets to print a message on the BiT. Messages are case-insensitive and unique. So a sequence of characters already used on a BiT cannot be duplicated. However, upon redemption, the message string becomes available for future mints.</li>
          <li>Waves Background - We love waves. Each BiT also gets a unique pseudo-randomly generated waves background during the minting process. These are unique to every BiT as well.</li>
          <li>Flex - Higher denominations have a higher character limit (4,8,16,32, and 60). ONE and DECA units also get seals.</li>
          </ul>
        </p>
      </ReadMeDiv>
      <ReadMeDiv>
        <p>
          <h3>
            Lifecycle of a <span style={{ color: "#eeb32b" }}>BiT</span>
          </h3>
          <ul>
              <li>
              Minting - Deposit fractions of WBTC with the Bank and the Mint
              mints a fully on-chain 
              <span style={{ color: "#eeb32b" }}>BiT</span>, backed by your
              deposit. All metadata associated with the BiT is on-chain. There
              is a one-time variable minting premium capped at 10%.
              </li>
              <li>
              Life - The minted <span style={{ color: "#eeb32b" }}>
                BiT </span>
              can then be held forever, traded, gifted, or transferred. There
              are no royalties except during mint for the life of a
              <span style={{ color: "#eeb32b" }}>BiT</span>.</li>
               <li>Redemption - Current owner of a BiT can redeem it anytime, during
              redemption the Bank will transfer the mint deposit to the owner’s
              wallet and instruct the Mint to burn the{" "}
              <span style={{ color: "#eeb32b" }}>BiT</span>.</li>
              </ul>
        </p>
      </ReadMeDiv>
      
      <ReadMeDiv>
          <p>
          
          <h3>Contract Addresses</h3>
          <ul>
          <li>
            <span style={{ color: "#eeb32b" }}>
              <span style={{ color: "#eeb32b" }}>BiT</span>S
            </span>{" "}
            Bank Address | {addresses.bitsNftBank}
          </li>
          <li>
            <span style={{ color: "#eeb32b" }}>
              <span style={{ color: "#eeb32b" }}>BiT</span>S
            </span>{" "}
            Mint Address | {addresses.bitsNftMint}
          </li>
          <li>
            Deposit Token Address ({props.depositToken?.symbol}) |{" "}
            {addresses.depositToken}
          </li>
        </ul>
        </p>
      </ReadMeDiv>
      <ReadMeDiv>
        <p>
        
          <h3>FAQ</h3>
          <ul>
          <li>
            SCARCITY - Messages are
            unique to every <span style={{ color: "#eeb32b" }}>BiT</span>. The
            same combination of characters (case-insensitive) cannot be used on
            another <span style={{ color: "#eeb32b" }}>BiT</span>. Redeeming a{" "}
            <span style={{ color: "#eeb32b" }}>BiT</span> will make the message
            available.
          </li>
          <li>
            FEES - Excluding gas
            costs, there is only a one time mint premium which is capped at 10%.
            There are no other royalties for transfer, sale, or redemption.
          </li>
          <li>
            VERIFICATION - Both the
            Bank and Mint contracts are verified on Etherscan. See below for
            addresses.
          </li>
        </ul>
        </p>
      </ReadMeDiv>
    </React.Fragment>
  );
}

export default ReadMeForm;
