import "./index.css";
import { DAppProvider, Mainnet, Rinkeby, } from "@usedapp/core";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

//Mainnet
export const SUPPORTED_CHAIN = Mainnet.chainId;
const ALCHEMY_MAINNET_ID = "HbNo9LGbasJsqNk1y_wYFofy6wy737uk";
const config = {
  readOnlyChainId: SUPPORTED_CHAIN,
  readOnlyUrls: {
   [Mainnet.chainId]: "https://eth-mainnet.alchemyapi.io/v2/" + ALCHEMY_MAINNET_ID
  },
  multicallVersion: 2,
  networks: [Mainnet],
}

//Rinkeby
// export const SUPPORTED_CHAIN = Rinkeby.chainId;
// const ALCHEMY_RINKEBY_ID = "28xk7DmoFVSzLVb_nGkNeLbYY7Sp-xJX";
// const config = {
//   readOnlyChainId: SUPPORTED_CHAIN,
//   readOnlyUrls: {
//    [Rinkeby.chainId]: "https://eth-rinkeby.alchemyapi.io/v2/" + ALCHEMY_RINKEBY_ID
//   },
//   multicallVersion: 2,
//   networks: [Rinkeby],
// }

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
        <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
