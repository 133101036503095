
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { BsInfoCircle } from "react-icons/bs";
import { IconContext } from "react-icons";
import { Image,  FormSelect, LeftSection, RightSection } from "./components";
import MILLI from "./samples/millisample.svg";
import CENTI from "./samples/centisample.svg";
import DECI from "./samples/decisample.svg";
import ONE from "./samples/onesample.svg";
import DECA from "./samples/decasample.svg";



function SamplesForm(props) {
  
  const [svgImage, setSvgImage] = useState(MILLI);
  const [depositAmount, setDepositAmount] = useState("0");


  const handleDepositAmountChange = (event) => {
    event.preventDefault();
    setDepositAmount(event.target.value);
  };



  useEffect(() => {
    if (depositAmount === "0") {
      setSvgImage(MILLI);
    } else if (depositAmount === "1") {
      setSvgImage(CENTI);
    } else if (depositAmount === "2") {
      setSvgImage(DECI);
    } else if (depositAmount === "3") {
      setSvgImage(ONE);
    } else if (depositAmount === "4") {
      setSvgImage(DECA);
    }
  }, [depositAmount
  ]);


 
  return (
    <form >
      <LeftSection>
      <label>
            Sample Deposit&nbsp;
            <IconContext.Provider
              value={{ size: "0.8em", style: { verticalAlign: "middle" } }}
            >
              <BsInfoCircle data-tip="Select the sample deposit into the mint.<br />These are just samples, the wave patterns are unique and psuedo-randomly generated on-chain." />
            </IconContext.Provider>
            &emsp;
            <FormSelect
              name="depositAmount"
              value={depositAmount}
              onChange={handleDepositAmountChange}
            >
              <option value="0">Milli (0.001 {props.depositToken?.symbol})</option>
              <option value="1">Centi (0.01 {props.depositToken?.symbol})</option>
              <option value="2">Deci (0.1 {props.depositToken?.symbol})</option>
              <option value="3">One (1 {props.depositToken?.symbol})</option>
              <option value="4">Deca (10 {props.depositToken?.symbol})</option>
            </FormSelect>
          </label>
          <ReactTooltip multiline />
          </LeftSection>
          <RightSection>
        <Image src={svgImage} alt={depositAmount} />
        </RightSection>
        
    </form>
  );
}

export default SamplesForm;
